.app{
  height:100vh;
  width: 100vw;
  background: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
p{
  margin: 0;
}
.container{
  height: 95%;
  width: 95%;
  background: white;
  border-radius: 4px;
  position: relative;
}

.videoContainer video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 4px;
}
.videoContainer{
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.videoOverlay{
  top: 0;
  border-radius: 4px;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
}
.videoOverlayMessages{
  border-top-left-radius: 4px;
  direction: rtl;
  user-select: none;
  overflow-y: auto;
  font-size: 14px;
  color: white;
  width: 30%;
  height: calc(100% - 45px);
  background: rgba(0, 0, 0, 0.3);
  max-width: 250px;
}
.videoOverlayMessages::-webkit-scrollbar{
  display: none;
}
.videoOverlayMessages > div:first-child{
  margin-top: 0;
}
.videoOverlayMessage{
  direction: ltr;
  margin: 10px 0;
  padding: 5px;
  background: rgba(0, 0, 0, 0.3);
}

.videoOverlayControls{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  align-items: flex-end;
}

.videoOverlayControlEnabled{
  height: 45px;
  background: green;
  color: #fcfcfc;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 2px;
}
.videoOverlayControlRequest{
  height: 45px;
  background: blue;
  color: #fcfcfc;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 2px;
}
.videoOverlayControlTopContainer{
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  width: 150px;
  padding: 10px;
  color: #fcfcfc;
}
.videoOverlayControlTopContainer p:last-child{
  margin-bottom: 10px;
}
.videoOverlayChat{
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 30%;
  max-width: 250px;
}
.videoOverlayChat input{
  border-bottom-left-radius: 4px;
  /*width: 90%;*/
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.6);
  color: #fcfcfc;
  padding: 0 10px;
}
.videoOverlayChatButton{
  cursor: pointer;
  background: blue;
  color: #fcfcfc;
  height: 45px;
  width: 90px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 4px;
}
.roombaCharging{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}