.dialog{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.dialogContainer{
    text-align: center;
    background: #b4b4b4;
    border-radius: 4px;
    height: 200px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    padding: 20px
}
.dialogContainer input{
    border-radius: 4px;
    font-size: 14px;
    border: none;
    height: 30px;
    width: 50%;
    outline: none;
    padding: 0 10px;

}
.dialogButtonContainer{
    color: #fcfcfc;
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    align-items: center;
    justify-content: center;
}

.dialogButton{
    border-radius: 4px;
    color: #fcfcfc !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 90px;
    background: #007777;
    cursor: pointer;
}